import { useFlags } from 'launchdarkly-react-client-sdk'
import useImage from 'use-image'

import { EAvailabilityType, EReservableType } from 'types/data.types'

import { getRoomSpaceScreenImages, getSeatSpaceScreenImages } from '../helpers/getPin'
import { getIsAvatarSeat, getIsMultiCounterSeat } from '../helpers/hasContent'
import { SpaceScreenSeatPin } from '../types'

export const useSpaceScreenPinsImages = (
    reservableType: EReservableType,
    typeSeat: SpaceScreenSeatPin,
    availabilityType: EAvailabilityType,
    selected: boolean,
    noSelectable: boolean,
    isFavorite = false,
    isMultiBooking = false,
    isBooked = false,
) => {
    const { towelPin } = useFlags()

    const { pinType, isOnlyBlockedTime } = typeSeat
    let imageSrc = ''

    switch (reservableType) {
        case EReservableType.SEAT:
        case EReservableType.PARKING: {
            imageSrc = getSeatSpaceScreenImages(
                reservableType,
                availabilityType,
                selected,
                noSelectable,
                isFavorite,
                isMultiBooking,
                isBooked,
                isOnlyBlockedTime,
                towelPin,
            )
            break
        }

        case EReservableType.ROOM:
            imageSrc = getRoomSpaceScreenImages(noSelectable, selected)
            break

        default:
            break
    }

    const hasAvatar = getIsAvatarSeat({
        isBooked,
        isMultiBooking,
        towelPin,
    })

    const hasMultiCounter = getIsMultiCounterSeat(pinType, reservableType)

    const [imagePin] = useImage(imageSrc)
    return { imagePin, hasAvatar, hasMultiCounter }
}
