import * as T from 'types/data.types'
import {
    AbsoluteCoords,
    EReservableType,
    PickerReservableBooking,
    PIN_CIRCLE,
    SeatPickerReservable,
    TReservable,
    EAvailabilityType,
    ReservableEmployeesType,
    Department,
} from 'types/data.types'

import { TTag } from '../../../types'

import type { Space, ReservableToDepartment } from 'graphql/autogenerate/schemas'

export type ReservableSpaceScreen = {
    name: string
    availabilityType: EAvailabilityType
    id: string
    x: number
    y: number
    bookings: Array<T.PickerReservableBooking>
    typeSeat: SpaceScreenSeatPin
    typeReservable: EReservableType
    nameRoom: string
    preview: Array<PIN_CIRCLE> | null
    startSpaceWorkingTime: string
    endSpaceWorkingTime: string
    selectable: boolean
    available: boolean
    tags: Array<TTag>
    zonePoints?: number[]
    color?: string
    availabilityByDatesAndTime?: boolean
    space?: Space | null
    departments?: Array<{ Department: Department }>
    isMultiBooking?: boolean
    isBooked?: boolean
    ReservableToEmployees?: {
        items: Array<ReservableEmployeesType>
    }
    ReservableToDepartments?: {
        items: Array<{ Department: Department }>
    }
    ReservableImages?: {
        items: Array<{ name: string; location: string }>
    }
    maxAmountOfPeople?: number
    externalRoomID?: string
    relatedReservableID?: string | null
    RelatedReservable?: TReservable
}

export type ColorAvatarSeat = {
    colorText: string
    colorBack: string
    colorSelectedText: string
    colorSelectedBack: string
}

export type SelectedReservable = {
    posX: number
    posY: number
    reservable: TReservable
    zoom: number
}

export type PickerReservableType = {
    id: string
    name: string
    preview: SeatPickerReservable['availabilityPreview']
    availabilityType: SeatPickerReservable['availabilityType']
    maxAmountOfPeople: number
    x: number
    y: number
    employee: SeatPickerReservable['Employee']
    isBlocked: boolean
    isFullDay: boolean
    bookings: Array<PickerReservableBooking>
}

export type PickerSelectedReservable = Pick<PickerReservableType, 'id' | 'x' | 'y'>

export type SeatPickerSelectedItemState = {
    seat: null | ReservableSpaceScreen
    room: null | PickerSelectedReservable
}

export type HoverSeatPicker = {
    seat: PickerReservableType
    coords: AbsoluteCoords
}
export type HoverSpaceScreenSeat = {
    seat: ReservableSpaceScreen
    coords: AbsoluteCoords
}

export type HoverSpaceScreenSeatStar = {
    title: string
    coords: AbsoluteCoords
}

export enum SpaceScreenMapPinType {
    BLOCKED = 'BLOCKED',
    UNAVAILABLE_FREE = 'UNAVAILABLE_FREE',
    UNAVAILABLE_MULTI_BOOKED = 'UNAVAILABLE_MULTI_BOOKED',
    FREE_FULL = 'FREE_FULL',
    ONE_BOOKED_FULL = 'ONE_BOOKED_FULL',
    ONE_BOOKED_PART = 'ONE_BOOKED_PART',
    MULTI_BOOKED_FULL = 'MULTI_BOOKED_FULL',
    MULTI_BOOKED_PART = 'MULTI_BOOKED_PART',
    REPEAT_FULL = 'REPEAT_FULL',
}

export type SpaceScreenSeatPin = {
    pinType: SpaceScreenMapPinType
    isOnlyBlockedTime: boolean
}
