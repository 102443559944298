import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import editBookingReducer from 'components/widgets/EditBooking/slices/editBookingSlice'
import editBookingResourceReducer from 'components/widgets/EditBookingResource/slices/editBookingResource'
import employeeFiltersReducer from 'components/widgets/Filters/slices/employeeFilters'
import calendarScheduleReducer from 'containers/Calendar/slices/calendarSchedule'
import dashboardReducer from 'containers/Dashboard/slices/dashboard'
import mobileRoomsReducer from 'containers/MobileRooms/slices/mobileRooms'
import resourcesReducer from 'containers/Resources/slices/resources'
import mobileRoomsV2 from 'containers/RoomsPageMobileV2/slices/mobileRoomsV2'
import meetingRoomsReducer from 'containers/RoomsPageV2/slices/meetingRooms'
import spaceScreenReducer from 'containers/SpaceScreen/slices/spaceScreen'
import calendarReducer from 'containers/UniversalCalendar/calendarSlice'

import loaderReducer from './slices/loader'
import messageReducer from './slices/message'
import userReducer from './slices/user'
import { createAppSyncApolloClient } from '../appSync/apollo-client'

const rootReducer = combineReducers({
    calendar: calendarReducer,
    message: messageReducer,
    user: userReducer,
    loader: loaderReducer,
    spaceScreen: spaceScreenReducer,
    calendarSchedule: calendarScheduleReducer,
    mobileRooms: mobileRoomsReducer,
    mobileRoomsV2: mobileRoomsV2,
    editBooking: editBookingReducer,
    dashboard: dashboardReducer,
    meetingRooms: meetingRoomsReducer,
    resources: resourcesReducer,
    editBookingResource: editBookingResourceReducer,
    employeeFilters: employeeFiltersReducer,
})

const client: ApolloClient<NormalizedCacheObject> = createAppSyncApolloClient({
    connectToDevTools: process.env.REACT_APP_NODE_ENV === 'development',
})

export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    client,
                },
            },
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
