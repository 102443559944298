import { SpaceScreenMapPinType, SpaceScreenSeatPin } from 'components/organisms/Reservable/types'
import { getEmployeesFromBookings } from 'containers/SpaceScreen/helpers'
import * as T from 'types/data.types'
import { EAvailabilityType, EReservableType, PIN_CIRCLE } from 'types/data.types'

import { checkPersonTeamAbilitySeat } from '../conditions'
import { getHoursFromISODateString, getTimeInHours, sortBookingsByStartTime } from '../index'

export const getTypeSpaceScreenMapPin = ({
    reservable,
    bookings,
    employeeId,
    employeeDepartmentIDs,
    startFilterTime,
    endFilterTime,
    isPersonalSeatAvailableToday,
    isTeamSeatAvailableToday,
    isForToday,
    isMultiBooking,
    isBooked,
}: {
    reservable: T.SeatPickerReservable
    bookings: Array<T.PickerReservableBooking>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    startFilterTime: string
    endFilterTime: string
    isPersonalSeatAvailableToday: boolean
    isTeamSeatAvailableToday: boolean
    isForToday: boolean
    isBooked: boolean
    isMultiBooking: boolean
}): SpaceScreenSeatPin => {
    const isBlocked = reservable.availabilityType === EAvailabilityType.UNAVALIABLE
    const isUnavailable = checkPersonTeamAbilitySeat(
        reservable,
        employeeId,
        employeeDepartmentIDs,
        isPersonalSeatAvailableToday,
        isTeamSeatAvailableToday,
        isForToday,
    )

    let isFullDay = false
    if (reservable.type === EReservableType.SEAT || reservable.type === EReservableType.PARKING) {
        isFullDay = checkIsFullDayByBookings(bookings, startFilterTime, endFilterTime)
    } else if (reservable.type === EReservableType.ROOM) {
        isFullDay = checkIsFullDayRoomPreview(reservable.availabilityPreviewByDates)
    }

    const isOnlyBlockedTime = Boolean(bookings.length === 1 && bookings[0].isBlocked)

    let pinType: SpaceScreenMapPinType

    if (isBlocked) {
        pinType = SpaceScreenMapPinType.BLOCKED
    } else if (isUnavailable && !isMultiBooking && !isFullDay) {
        pinType = SpaceScreenMapPinType.UNAVAILABLE_FREE
    } else if (isUnavailable && isMultiBooking && !isFullDay) {
        pinType = SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED
    } else if (isFullDay && isMultiBooking) {
        pinType = SpaceScreenMapPinType.MULTI_BOOKED_FULL
    } else if (!isFullDay && isMultiBooking) {
        pinType = SpaceScreenMapPinType.MULTI_BOOKED_PART
    } else if (isFullDay && !isMultiBooking) {
        pinType = SpaceScreenMapPinType.ONE_BOOKED_FULL
    } else if (!isFullDay && !isMultiBooking && isBooked) {
        pinType = SpaceScreenMapPinType.ONE_BOOKED_PART
    } else {
        pinType = SpaceScreenMapPinType.FREE_FULL
    }

    return {
        pinType,
        isOnlyBlockedTime,
    }
}

const checkIsFullDayRoomPreview = (previewHours?: PIN_CIRCLE[]): boolean => {
    return previewHours ? previewHours.every((hour) => hour === PIN_CIRCLE.FULL) : false
}

const checkIsFullDayByBookings = (
    bookings: Array<T.PickerReservableBooking>,
    startFilterTime: string,
    endFilterTime: string,
): boolean => {
    if (bookings.length === 0) {
        return false
    }

    const sortedBookings = sortBookingsByStartTime(bookings, true)
    let hasFullBooking = false

    // Convert filter times to numerical hours
    const filterStart = getTimeInHours(startFilterTime)
    const filterEnd = getTimeInHours(endFilterTime)

    // Initialize joined time with the first booking's start and end times
    const joinedTime = {
        startTime: getTimeInHours(getHoursFromISODateString(sortedBookings[0].startTime)),
        endTime: getTimeInHours(getHoursFromISODateString(sortedBookings[0].endTime)),
    }

    for (const booking of sortedBookings) {
        const { isFullDay, startTime, endTime } = booking

        if (isFullDay) {
            hasFullBooking = true
            break
        }

        const bookingStart = getTimeInHours(getHoursFromISODateString(startTime))
        const bookingEnd = getTimeInHours(getHoursFromISODateString(endTime))

        // Check if there is a gap between current booking and joined time
        if (bookingStart > joinedTime.endTime || bookingEnd < joinedTime.startTime) {
            break
        }

        // Extend the joined time
        joinedTime.startTime = Math.min(bookingStart, joinedTime.startTime)
        joinedTime.endTime = Math.max(bookingEnd, joinedTime.endTime)

        if (joinedTime.startTime <= filterStart && joinedTime.endTime >= filterEnd) {
            hasFullBooking = true
            break
        }
    }

    return hasFullBooking
}

export const checkIsMultiBookingSpaceScreenPin = (bookings: Array<T.PickerReservableBooking>): boolean => {
    const employees = getEmployeesFromBookings(bookings)
    return employees.length > 1
}
