import { EAvailabilityType, MainEmployeeData, SeatPickerReservable } from 'types/data.types'

export const checkPersonTeamAbilitySeat = (
    res: SeatPickerReservable,
    employeeId: string,
    departmentIDs: MainEmployeeData['departmentIDs'],
    isPersonalSeatAvailableToday?: boolean,
    isTeamSeatAvailableToday?: boolean,
    isForToday?: boolean,
) => {
    const conditions: Partial<Record<EAvailabilityType, boolean>> = {
        [EAvailabilityType.BOOKED_FOR_PERSON]:
            !(isPersonalSeatAvailableToday && isForToday) &&
            !!res.ReservableToEmployees?.items.every(
                (resDepartment) => !resDepartment.employee?.id || resDepartment.employee.id !== employeeId,
            ),
        [EAvailabilityType.BOOKED_FOR_TEAM]:
            !(isTeamSeatAvailableToday && isForToday) &&
            !!res.ReservableToDepartments?.items.every(
                (resDepartment) =>
                    !resDepartment.Department?.id || !(departmentIDs || []).includes(resDepartment.Department.id),
            ) &&
            (!res.Department?.name || !(departmentIDs || []).includes(res.Department?.name)),
    }
    return conditions[res.availabilityType]
}
