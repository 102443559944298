import { ReservableSpaceScreen, SpaceScreenMapPinType } from 'components/organisms/Reservable'
import { RepeatType, Space } from 'graphql/autogenerate/schemas'
import { BookingType } from 'types/common.types'
import * as T from 'types/data.types'
import { EReservableType, TCompany } from 'types/data.types'
import { EUserGroup } from 'types/user.types'

import { getTypeRepeatSpaceMapPin } from './getTypeRepeatSpaceMapPin'
import { checkIsMultiBookingSpaceScreenPin, getTypeSpaceScreenMapPin } from './getTypeSpaceScreenMapPin'
import { isAvailableReservable } from './reservable-availability/isAvailableReservable'

type ConvertReservables = {
    reservables: Array<T.SeatPickerReservable>
    employeeId: string
    employeeDepartmentIDs: T.MainEmployeeData['departmentIDs']
    suggestedSeatID: string | null
    startFilterTime: string
    endFilterTime: string
    startSpaceWorkingTime: string
    endSpaceWorkingTime: string
    isAdmin: boolean
    isRepeat?: boolean
    isOnlyFreeSelectable?: boolean
    isPersonalSeatAvailableToday?: boolean
    isTeamSeatAvailableToday?: boolean
    isForToday?: boolean
    bookingStatus?: BookingType
    company?: TCompany
    userGroup?: EUserGroup
    filter?: {
        dates: string[]
        startTime: string
        endTime: string
        employees: {
            id: string
            departmentIDs: string[]
        }[]
        bookingRepeatType?: RepeatType
        daysOfWeek: string[]
    }
}

export const convertReservables = ({
    reservables,
    employeeId,
    employeeDepartmentIDs,
    suggestedSeatID,
    startFilterTime,
    endFilterTime,
    startSpaceWorkingTime,
    endSpaceWorkingTime,
    isAdmin,
    isRepeat = false,
    isOnlyFreeSelectable = false,
    isPersonalSeatAvailableToday = false,
    isTeamSeatAvailableToday = false,
    isForToday = false,
    bookingStatus,
    company,
    userGroup = EUserGroup.EMPLOYEE,
    filter,
}: ConvertReservables) => {
    const convertedReservables: ReservableSpaceScreen[] = []

    const seatVariants = {
        bookedSeat: null as ReservableSpaceScreen | null,
        suggestedSeat: null as ReservableSpaceScreen | null,
        randomSeat: null as ReservableSpaceScreen | null,
    }

    const allBookings: T.PickerReservableBooking[] = []
    const seatBookings: T.PickerReservableBooking[] = []

    reservables.forEach((reservable) => {
        const {
            id,
            name,
            x,
            y,
            type,
            availabilityType,
            availabilityByDatesAndTime,
            availabilityPreviewByDates,
            zonePoints,
            color,
            BookingReservablesByCreatedAt,
            BookingsByDateAndDayOfWeek,
            Tags,
        } = reservable

        const bookings =
            type === EReservableType.PARKING && BookingReservablesByCreatedAt
                ? BookingReservablesByCreatedAt.items.map((item) => item.booking)
                : BookingsByDateAndDayOfWeek?.items || []

        const isSeatWithMyBooking = bookings.some((booking) => booking?.Employee?.id === employeeId)

        if (type !== EReservableType.PARKING) {
            seatBookings.push(...bookings)
        }

        allBookings.push(...bookings)

        const isMultiBooking = checkIsMultiBookingSpaceScreenPin(bookings)
        const isBooked = bookings.length > 0

        const { selectable, available } =
            company && filter
                ? isAvailableReservable(reservable, filter, company, userGroup)
                : { selectable: false, available: false }

        const typeSeat = isRepeat
            ? getTypeRepeatSpaceMapPin({
                  reservable,
                  employeeId,
                  employeeDepartmentIDs,
                  isPersonalSeatAvailableToday,
                  isTeamSeatAvailableToday,
                  isForToday: false,
              })
            : getTypeSpaceScreenMapPin({
                  reservable,
                  isMultiBooking,
                  isBooked,
                  bookings,
                  employeeId,
                  employeeDepartmentIDs,
                  startFilterTime,
                  endFilterTime,
                  isPersonalSeatAvailableToday,
                  isTeamSeatAvailableToday,
                  isForToday,
              })

        // ToDo refactor this trash
        const noSelectable = (() => {
            let result = true
            if (isOnlyFreeSelectable) {
                result = !isSeatWithMyBooking && typeSeat.pinType !== SpaceScreenMapPinType.FREE_FULL
            } else {
                const isBlockedOrRepeatFull =
                    typeSeat.pinType === SpaceScreenMapPinType.BLOCKED ||
                    typeSeat.pinType === SpaceScreenMapPinType.REPEAT_FULL
                const isUnavailableAndNotAdmin =
                    !isAdmin &&
                    (typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_FREE ||
                        typeSeat.pinType === SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED)

                result = isBlockedOrRepeatFull || isUnavailableAndNotAdmin
            }

            if (reservable.type !== EReservableType.ROOM) {
                if (
                    bookingStatus &&
                    bookingStatus === BookingType.TEAM_EVENT &&
                    reservable.type !== EReservableType.ZONE
                ) {
                    result = true
                } else {
                    result = !selectable
                }
            }

            return result
        })()

        const tags = Tags?.items.map((tag) => ({ name: tag?.Tag?.name, id: tag?.Tag?.id })) || []

        const convertedReservable: ReservableSpaceScreen = {
            name,
            availabilityType,
            id,
            x,
            y,
            typeReservable: type,
            typeSeat,
            bookings,
            nameRoom: name,
            preview: availabilityPreviewByDates,
            endSpaceWorkingTime,
            startSpaceWorkingTime,
            selectable: !noSelectable,
            available,
            tags,
            zonePoints,
            color,
            space: (reservable?.Space as Space) || null,
            availabilityByDatesAndTime,
            isMultiBooking,
            isBooked,
            ReservableToEmployees: reservable.ReservableToEmployees,
            ReservableToDepartments: reservable.ReservableToDepartments,
        }

        if (
            type === EReservableType.SEAT ||
            type === EReservableType.ROOM ||
            type === EReservableType.PARKING ||
            type === EReservableType.ZONE
        ) {
            convertedReservables.push(convertedReservable)
        }

        if (isSeatWithMyBooking) {
            seatVariants.bookedSeat = convertedReservable
        }

        if (convertedReservable.id === suggestedSeatID) {
            seatVariants.suggestedSeat = convertedReservable
        }

        if (typeSeat.pinType === SpaceScreenMapPinType.FREE_FULL) {
            seatVariants.randomSeat = convertedReservable
        }
    })

    return {
        convertedReservables,
        suggestedConvertedSeat: seatVariants.bookedSeat || seatVariants.suggestedSeat || seatVariants.randomSeat,
        allBookings,
        seatBookings,
    }
}
