const getInitials = (str: string | null) => {
    if (str === null || !str) {
        return ''
    }
    const arr = str.split(' ')

    const initials =
        arr.length > 1 ? (arr.shift() as string).charAt(0) + (arr.pop() as string).charAt(0) : str.substring(0, 1)

    return initials.toUpperCase()
}

export default getInitials
