import { EReservableType } from 'types/data.types'

import { SpaceScreenMapPinType } from '../types'

export const getIsAvatarSeat = ({ isBooked, isMultiBooking, towelPin }) => {
    if (towelPin) {
        return false
    }
    if (isBooked) {
        return true
    }
    if (isMultiBooking) {
        return false
    }
    return false
}

export const getIsMultiCounterSeat = (pinType: SpaceScreenMapPinType, reservableType: EReservableType) => {
    switch (pinType) {
        case SpaceScreenMapPinType.MULTI_BOOKED_PART:
        case SpaceScreenMapPinType.UNAVAILABLE_MULTI_BOOKED:
            return true

        case SpaceScreenMapPinType.MULTI_BOOKED_FULL: {
            return !(reservableType === EReservableType.SEAT)
        }
        default:
            return false
    }
}
